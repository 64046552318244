@import 'import';
.root {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;

  width: 100%;
  padding: 30px 0;

  :global(.loading-skeleton) {
    --base-color: #{$gray-110};

    display: flex;
    height: inherit;
  }
}

.header {
  width: 25%;
  height: 30px;
}

.dropdowns {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.regions {
  width: 32%;
  height: 67px;
}

.dates {
  width: 32%;
  height: 67px;
}

.filters {
  width: 90px;
  height: 67px;
  margin-left: 18px;
}
