@import 'import';
.PackageCardTextContent__textContent {
  display: flex;
  flex: 1 0 30%;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  padding: 30px 20px;

  text-align: center;

  .sailingCards {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
  }

  .PackagePrice {
    @media (max-width: $container-sm-max-width) {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 20px 20px 22px;
  }

  @media (max-width: $container-sm-width-768) and (min-width: $container-xxs-width) {
    padding: 30px 30px 22px;
  }
}

.PackageCardTextContent__heading {
  margin-bottom: 12px;

  @include font($size: rem(11), $color: $grey-scale);
}
