@import 'import';
.PackagePrice {
  position: relative;
  margin-bottom: 24px;

  &__upliftContainer {
    position: absolute;
    bottom: 0;
    transform: translate(-50%);

    display: inline-block;

    width: 100%;
  }

  &:has(&__upliftContainer:not(:empty)) {
    padding-bottom: 24px;
  }

  .priceTotal {
    @include font($size: rem(30), $weight: 'bold', $line-height: rem(36), $color: $black);
  }

  .priceFootNote {
    @include font($size: rem(13), $line-height: rem(19));
  }

  .upliftSkeletonContainer {
    margin-top: 2px;
  }

  .loading-skeleton {
    @include media-breakpoint-down(sm) {
      opacity: 0.4;
    }
  }

  .fromLabel {
    @include font($size: rem(12), $line-height: rem(16));

    margin-bottom: 4px;
  }

  .uplift.uplift-font {
    padding-top: 0;
  }

  .price-with-discount {
    .amount {
      @include font($size: rem(30), $weight: 'bold', $line-height: rem(36), $color: $vv-red);
    }

    .original-amount {
      @include font($size: rem(17), $weight: 'normal', $line-height: rem(25), $color: currentcolor);
    }
  }

  .packageCardupliftTooltip {
    cursor: pointer;

    position: relative;
    top: 3.25px;

    display: inline-block;

    width: rem(10);
    height: rem(10);
    margin-left: rem(2.5);

    font-size: rem(9);
    font-weight: bold;
    color: $black;

    background: $upliftIconBackground;
    border-radius: 50%;

    .packageCardupliftTooltipIcon {
      position: relative;
      top: -3.25px;
    }
  }
}
